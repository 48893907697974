<template>
  <div>
    <h3>Quem assina pela empresa?</h3>
    <validation-observer ref="representativeForm" #default="{ invalid }">
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Nome" label-for="representative-name">
            <validation-provider #default="{ errors }" name="Nome" rules="required" vid="name">
              <b-form-input id="representative-name" v-model="representative.nome"
                :state="errors.length > 0 ? false : null" name="representative-name" :maxlength="100" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Email" label-for="representative-email">
            <validation-provider #default="{ errors }" name="Email" rules="required|email" vid="email">
              <b-form-input id="representative-email" v-model="representative.email"
                :state="errors.length > 0 ? false : null" name="representative-email" :maxlength="50" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Celular" label-for="representative-cellphone">
            <validation-provider #default="{ errors }" name="Celular" rules="required" vid="cellphone">
              <b-form-input id="representative-cellphone" v-model="representative.celular" v-mask="['(##) # ####-####']"
                :state="errors.length > 0 ? false : null" name="representative-cellphone" type="tel" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="RG" label-for="representative-rg">
            <validation-provider #default="{ errors }" name="RG" rules="required" vid="rg">
              <b-form-input id="representative-rg" v-model="representative.rg" :state="errors.length > 0 ? false : null"
                name="representative-rg" :maxlength="16" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="RG/Orgão emissor" label-for="representative-issuer">
            <validation-provider #default="{ errors }" name="RG/Orgão emissor" rules="required" vid="issuer">
              <b-form-input id="representative-issuer" v-model="representative.orgao_emissor_rg"
                :state="errors.length > 0 ? false : null" name="representative-issuer" :maxlength="10" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="RG/Estado" label-for="representative-issuerState">
            <validation-provider #default="{ errors }" name="RG/Estado" rules="required" vid="issuerState">
              <v-select id="representative-issuerState" v-model="representative.rg_uf"
                :state="errors.length > 0 ? false : null" name="representative-issuerState" :clearable="false"
                :options="ufOptions" :reduce="(value) => value.value" class="per-page-selector d-inline-block w-100" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="CPF" label-for="representative-cpf">
            <validation-provider #default="{ errors }" name="CPF" rules="required" vid="cpf">
              <b-form-input id="representative-cpf" v-model="representative.cpf" v-mask="'###.###.###-##'"
                :state="errors.length > 0 ? false : null" name="representative-cpf" type="text" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Tipo de representante" label-for="representative-representativeType">
            <validation-provider #default="{ errors }" name="Tipo de representante" rules="required"
              vid="representativeType">
              <v-select id="representative-representativeType" v-model="representative.tipo" :clearable="false"
                :options="representativeTypes" :reduce="(tipo) => tipo.value"
                class="per-page-selector d-inline-block w-100" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Comprovante de residência" label-for="representative-comprovante_residencia">
            <validation-provider #default="{ errors }" name="Comprovante de residência" rules="required"
              vid="comprovante_residencia">
              <cc-dropzone id="comprovante_residencia" ref="comprovante_residencia"
                v-model="representative.comprovante_residencia" accept-formats=".pdf, .png, .jpg"
                subtitle="'Envie seu arquivo nos formato .PDF, .PNG ou .JPG'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col sm="12" md="6" lg="4">
          <b-form-group label="Documento de identidade" label-for="representative-issuerState">
            <validation-provider #default="{ errors }" name="Documento de identidade" rules="required"
              vid="documento_identidade">
              <cc-dropzone id="documento_identidade" ref="documento_identidade"
                v-model="representative.documento_identidade" accept-formats=".pdf, .png, .jpg"
                subtitle="'Envie seu arquivo nos formato .PDF, .PNG ou .JPG'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col v-show="isProcurador" sm="12" md="6" lg="4">
          <b-form-group label="Procuração" label-for="representative-issuerState">
            <validation-provider #default="{ errors }" name="Procuração" :rules="{ 'required': isProcurador }"
              vid="procuracao">
              <cc-dropzone id="procuracao" ref="procuracao" v-model="representative.procuracao" :required="isProcurador"
                accept-formats=".pdf, .png, .jpg" subtitle="'Envie seu arquivo nos formato .PDF, .PNG ou .JPG'" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row class="mb-1">
        <b-col :class="{
          'justify-content-between': representative.id,
          'justify-content-end': !representative.id,
        }" class="d-flex flex-row" cols="12">
          <b-button v-show="representative.id" variant="outline-primary" @click="deleteRepresentante">
            <feather-icon icon="TrashIcon" size="16" />
            Excluir assinante
          </b-button>
          <div>
            <b-button class="mr-1" variant="outline-primary" @click="$emit('cancel')">Cancelar</b-button>
            <b-button :disabled="invalid" variant="primary" @click="save">{{
              saveButtonLabel
            }}</b-button>
          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import { BButton, BCol, BFormGroup, BFormInput, BRow } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import CcDropzone from "@core/components/cc-dropzone/CcDropzone.vue";
import vSelect from "vue-select";
import { required } from "@validations";
import { mask } from "vue-the-mask";
import { brazilianStatesOptions } from "@core/mixins/ui/forms";

export default {
  name: "RepresentativeForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    CcDropzone,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BButton,
    vSelect,
  },
  props: {
    representativeData: {
      type: Object,
      default: () => ({}),
    },
  },
  directives: {
    mask,
  },
  mixins: [brazilianStatesOptions],
  data() {
    return {
      representativeTypes: [
        { label: "Procurador", value: "procurador" },
        { label: "Sócio", value: "socio" },
        { label: "Diretor", value: "diretor" },
        { label: "Avalista", value: "avalista" },
        { label: "Sócio / Avalista", value: "socio_avalista" },
        { label: "Diretor / Avalista", value: "diretor_avalista" },
        { label: "Procurador / Avalista", value: "procurador_avalista" },
      ],
      assignTypes: [
        { text: "Eletrônica", value: "ELECTRONIC" },
        { text: "Física", value: "PHYSICAL" },
      ],
      representative: {
        empresa_id: "",
        nome: "",
        email: "",
        celular: "",
        rg: "",
        orgao_emissor_rg: "",
        rg_uf: "",
        cpf: "",
        tipo: "",
        comprovante_residencia: "",
        documento_identidade: "",
        procuracao: "",
      },
      required,
    };
  },
  mounted() {
    if (this.representativeData.id) this.representative = this.representativeData;
    if (this.$route.params.id) this.representative.empresa_id = this.$route.params.id;
  },
  methods: {
    async save() {
      try {
        this.$swal.showLoading();
        await this.$store.dispatch("company/saveRepresentative", this.representative);

        this.$swal.fire({
          title: "Sucesso!",
          text: "Representante cadastrado com sucesso!",
          icon: "success",
        });

        this.representative = {
          nome: "",
          email: "",
          celular: "",
          rg: "",
          orgao_emissor_rg: "",
          rg_uf: "",
          cpf: "",
          tipo: "",
        };

        this.representative.procuracao = "";
        this.representative.documento_identidade = "";
        this.representative.comprovante_residencia = "";

        this.$refs.representativeForm.reset()
        this.$refs.documento_identidade.clear()
        this.$refs.comprovante_residencia.clear()
        this.$refs.procuracao.clear()

        this.$emit("saved");
      } catch (error) {
        console.log("error", error);
        this.$swal.fire({
          title: "Erro!",
          text: "Não foi possível cadastrar o representante!",
          icon: "error",
        });
      }
    },
    async deleteRepresentante() {
      try {
        this.$swal.showLoading();
        await this.$store.dispatch(
          "company/destroyRepresentative",
          this.representative.id
        );
        this.$swal.fire({
          title: "Sucesso!",
          text: "Representante excluído com sucesso!",
          icon: "success",
        });

        this.representative = {
          nome: "",
          email: "",
          celular: "",
          rg: "",
          orgao_emissor_rg: "",
          rg_uf: "",
          cpf: "",
          tipo: "",
        };

        this.representative.procuracao = "";
        this.representative.documento_identidade = "";
        this.representative.comprovante_residencia = "";

        this.$emit("deleted");
        this.$router.go()
      } catch (error) {
        console.log(error)
        this.$swal.fire({
          title: "Erro!",
          text: "Não foi possível excluir o representante!",
          icon: "error",
        });
      }
    },
  },
  computed: {
    saveButtonLabel() {
      return !this.representative.id ? "Adicionar assinante" : "Salvar alterações";
    },
    isProcurador() {
      const allowedTypes = ["procurador", "procurador_avalista"];
      return allowedTypes.includes(this.representative.tipo);
    },
  },
};
</script>
